import { Link } from "gatsby"
import React, { Component } from 'react'
import loadable from '@loadable/component'

import Loader from "../components/loader";

const Head = loadable(() => import("../components/head"));
const InnerLayout = loadable(() => import('../components/Innerlayout'));
const EngThreeDNFTGame = loadable(() => import("../components/ThreeDNFTGame/english.js"));
const ArabicThreeDNFTGame = loadable(() => import("../components/ThreeDNFTGame/arabic.js"));
const ChineseThreeDNFTGame = loadable(() => import("../components/ThreeDNFTGame/chinese.js"));

const schema = {
  "@context": "http://schema.org/",
  "@graph": [
    {
      "@type": "product",
      "image": "https://softtik.com/images/nft-collection/invest-section-8.webp",
      "name": "Top 3D NFT Game Development Company",
      "description": "Softtik is the most sought-after 3D NFT game development company with knowledgeable professionals offering high-end solutions and services.",
      "brand": "Softtik Technologies",
      "aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": "4.8",
        "reviewCount": "133"
      }
    },
    {
      "@type": "FAQPage",
      "mainEntity": [{
        "@type": "Question",
        "name": "What is a 3D NFT game developer?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "<p>A 3D NFT game developer is someone who knows about NFTs, blockchain, and gaming. He also understands how to combine these technologies to produce the most effective NFT gaming solutions.</p>"
        }
      }, {
        "@type": "Question",
        "name": "Is there a market for 3D NFT games?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "<p>3D NFTs are in huge demand since the P2E model has been implemented in the gaming industry. 3D NFTs combined with other latest technologies are changing the face of traditional video gaming.</p>"
        }
      }, {
        "@type": "Question",
        "name": "How much does 3D NFT game development cost?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "<p>The cost of the 3D NFT game development depends on your feature requirements. Contact <b>Softtik Technologies</b>, submit your feature requirements, and get the estimated development cost.</p>"
        }
      }
      ]
    }
  ]
}

export class ThreeDNFTGame extends Component {

  constructor(props) {
    super(props);
    this.state = {
      lang: "English"
    }
  };


  async componentDidMount() {
    let region = localStorage.getItem('region');

    if (region == 'ae') this.setState({ lang: "Arabic" });
    else if (region == 'cn') this.setState({ lang: "China" });
    else this.setState({ lang: "English" });
  };

  render() {
    let { lang } = this.state;
    return (
      <>
        <InnerLayout header='Main' fallback={<Loader />}>
          <Head
            type="Website"
            fallback={<Loader />}
            schemaMarkup={schema}
            path="/3d-nft-game-development-services/"
            title="3D NFT Game Development Company - Softtik Technologies"
            thumbnail="https://softtik.com/images/nft-collection/invest-section-8.webp"
            description="Softtik is the most sought-after 3D NFT game development company with knowledgeable professionals offering high-end solutions and services."
          />
          <main>
            <div id="wrapper">
              <div className={`collection-page game-development-page ${lang == "Arabic" && 'rtl'}`}>
                {lang == "Arabic"
                  ? <ArabicThreeDNFTGame />
                  : lang == "China"
                    ? <ChineseThreeDNFTGame />
                    : <EngThreeDNFTGame />
                }
              </div>
            </div>
          </main>
        </InnerLayout >
      </>
    )
  };
};

export default ThreeDNFTGame;